import React from "react";
import Routes from "./routes";

class App extends React.Component {

    render() {
        return (
            <Routes />
        );
    }
}

export default App;